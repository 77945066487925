import React, { useEffect, useRef, useState } from "react";
import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants";
import { Box } from "@mui/material";
import { setSearchBussinessLocation } from "../redux/searchBussiness/searchBussinessActions";
import { useDispatch } from "react-redux";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchLocationInput = ({ area, setArea }) => {
  const [query, setQuery] = useState(area ?? "");
  const autoCompleteRef = useRef(null);
  const dispatch = useDispatch();
  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
        componentRestrictions: { country: "IN" },
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    // const addressObject = await autoComplete.getPlace();
    const addressObject = await autoComplete.getPlace();

    const query = addressObject.formatted_address;

    const city = addressObject?.address_components.filter(
      (f) =>
        JSON.stringify(f.types) === JSON.stringify(["locality", "political"])
    )[0]?.short_name;
    const state = addressObject?.address_components.filter(
      (f) =>
        JSON.stringify(f.types) ===
        JSON.stringify(["administrative_area_level_1", "political"])
    )[0]?.short_name;
    const displayCity = addressObject?.address_components.filter(
      (f) =>
        JSON.stringify(f.types) === JSON.stringify(["locality", "political"])
    )[0]?.long_name;
    const displayState = addressObject?.address_components.filter(
      (f) =>
        JSON.stringify(f.types) ===
        JSON.stringify(["administrative_area_level_1", "political"])
    )[0]?.long_name;

    // console.log("city", city);
    // console.log("state", state);
    // console.log("displayCity", displayCity);
    // console.log("displayState", displayState);

    // console.log("https://github.com/erpushpendrasingh", addressObject);
    // console.log("https:// country", addressObject.country);
    updateQuery(query);
    // console.log({ query });

    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };
    setArea(displayCity ?? displayState ?? "");
    dispatch(
      setSearchBussinessLocation(
        displayCity ?? displayState ?? ""

        // response.data.address.town ||
        // response.data.address.village ||
      )
    );
    // console.log({ latLng });
    // setSelectedLocation(latLng);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (area !== "") {
      setQuery(area);
    }
  }, [area]);

  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
        maxWidth: { xs: "97%", sm: "300px" }, // Set max width for responsiveness
      }}
    >
      <input
        ref={autoCompleteRef}
        className="form-control"
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search Places ..."
        value={query}
        style={{
          zIndex: 1000,
          height: "40px",
          width: "100%", // Ensure full width
          padding: "10px",
          boxSizing: "border-box",
        }}
      />
    </Box>
  );
};
// <Box>
//   <input
//     ref={autoCompleteRef}
//     className="form-control"
//     onChange={(event) => setQuery(event.target.value)}
//     placeholder="Search Places ..."
//     value={query}
//     style={{
//       zIndex: "1000",
//       height: "30px",
//       padding: "10px",
//     }}
//   />
// </Box>

export default SearchLocationInput;
