import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  Link,
  Divider,
  IconButton,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/auth/authActions";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hash, pathname } = useLocation();

  const [open, setOpen] = useState(true);
  const [openOtp, setOpenOtp] = useState(false);
  const [username, setUsername] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const handleClose = () => {
    setOpen(false);
    navigate(pathname);
  };

  const handleLogin = async () => {
    if (
      username === "" ||
      location === "" ||
      pincode === "" ||
      phoneNumber === ""
    ) {
      alert("All fields are required Name, Mobile, Location, Pincode");
      return;
    }
    await axios
      .post("https://backend-janta-rides.vercel.app/api/webUser/send-otp", {
        phoneNumber: `+91${phoneNumber}`,
        name: username,
        location,
        pincode,
      })
      .then((res) => {
        const result = res?.data;
        if (result?.success && result?.message === "OTP sent successfully") {
          setOpen(false);
          setOpenOtp(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleOtpVerify = async () => {
    await axios
      .post("https://backend-janta-rides.vercel.app/api/webUser/verify-otp", {
        phoneNumber: `+91${phoneNumber}`,
        otp: otp,
        name: username,
        location,
        pincode,
      })
      .then((res) => {
        const result = res?.data;
        if (
          result?.success &&
          result?.message === "OTP verified, user logged in successfully"
        ) {
          dispatch(login(res?.data));
          setOpen(false);
          setOpenOtp(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      if (user && user?.role === "user") {
        navigate("/");
      }
    }
  }, [isAuthenticated, navigate, user?.role]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: 400,
            borderRadius: 2,
            padding: 3,
          },
        }}
      >
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            component="h1"
            align="center"
            sx={{ fontWeight: "bold", color: "#000" }}
          >
            Janta Rides
          </Typography>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            Login for a seamless experience
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 2 }}>Name</Typography>
            <TextField
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              placeholder="Enter Fullname*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 4 }}>{`+91    `}</Typography>
            <TextField
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
              placeholder="Enter Mobile Number*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 1 }}>Pincode</Typography>
            <TextField
              onChange={(e) => setPincode(e.target.value)}
              fullWidth
              placeholder="Enter area pincode*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography sx={{ mr: 1 }}>Location</Typography>
            <TextField
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
              placeholder="Enter Location*"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>

          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={
              <Typography variant="body2">
                I Agree to Terms and Conditions{" "}
                <Link href="#" underline="hover">
                  T&C's Privacy Policy
                </Link>
              </Typography>
            }
          />

          <Button
            fullWidth
            variant="contained"
            onClick={handleLogin}
            sx={{
              backgroundColor: "#007BFF",
              color: "#fff",
              borderRadius: "8px",
              padding: "10px",
              textTransform: "none",
              fontWeight: "bold",
              mb: 2,
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            Login with OTP
          </Button>
        </Box>
      </Dialog>
      <Dialog
        open={openOtp}
        PaperProps={{
          sx: {
            width: 400,
            borderRadius: 2,
            padding: 3,
          },
        }}
      >
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            variant="h5"
            component="h1"
            align="center"
            sx={{ fontWeight: "bold", color: "teal", my: 1 }}
          >
            Verify your OTP
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
              size="small"
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              placeholder="Enter OTP"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                },
              }}
            />
          </Box>

          <Button
            fullWidth
            variant="contained"
            onClick={handleOtpVerify}
            sx={{
              backgroundColor: "#007BFF",
              color: "#fff",
              borderRadius: "8px",
              padding: "10px",
              textTransform: "none",
              fontWeight: "bold",
              mb: 2,
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            Verify OTP
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default LoginPage;

// import React from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   Typography,
//   Link,
//   Divider,
//   IconButton,
//   InputAdornment,
// } from "@mui/material";
// import GoogleIcon from "@mui/icons-material/Google";

// const LoginPage = () => {
//   return (
//     <Box
//       sx={{
//         width: "400px",
//         margin: "auto",
//         padding: "24px",
//         borderRadius: "12px",
//         boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//         backgroundColor: "white",
//       }}
//     >
//       <Box display="flex" alignItems="center" mb={2}>
//         <img
//           src="https://example.com/jantarides-logo.png"
//           alt="jantarides"
//           style={{ width: "120px" }}
//         />
//       </Box>
//       <Typography variant="h6" mb={1}>
//         Welcome
//       </Typography>
//       <Typography variant="subtitle1" color="textSecondary" mb={2}>
//         Login for a seamless experience
//       </Typography>
//       <TextField
//         fullWidth
//         label="Enter Mobile Number*"
//         variant="outlined"
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <Typography variant="subtitle1">+91</Typography>
//             </InputAdornment>
//           ),
//         }}
//       />
//       <FormControlLabel
//         control={<Checkbox />}
//         label={
//           <Typography variant="body2">
//             I Agree to{" "}
//             <Link href="#" underline="hover">
//               Terms and Conditions
//             </Link>{" "}
//             &{" "}
//             <Link href="#" underline="hover">
//               T&C's Privacy Policy
//             </Link>
//           </Typography>
//         }
//         sx={{ marginTop: "16px", marginBottom: "16px" }}
//       />
//       <Button
//         fullWidth
//         variant="contained"
//         color="primary"
//         size="large"
//         sx={{ padding: "12px 0", marginBottom: "16px" }}
//       >
//         Login with OTP
//       </Button>
//       <Divider>
//         <Typography variant="body2" color="textSecondary">
//           Or Login Using
//         </Typography>
//       </Divider>
//       <Button
//         fullWidth
//         variant="outlined"
//         startIcon={<GoogleIcon />}
//         sx={{
//           padding: "10px 0",
//           marginTop: "16px",
//           borderColor: "#4285F4",
//           color: "#4285F4",
//         }}
//       >
//         Google
//       </Button>
//       <Button
//         variant="text"
//         color="primary"
//         fullWidth
//         sx={{ marginTop: "16px" }}
//       >
//         Skip
//       </Button>
//     </Box>
//   );
// };

// export default LoginPage;

// // import React from "react";
// // import {
// //   Box,
// //   Button,
// //   Checkbox,
// //   FormControlLabel,
// //   TextField,
// //   Typography,
// //   Link,
// //   Divider,
// // } from "@mui/material";
// // import GoogleIcon from "@mui/icons-material/Google"; // Assuming Google icon is used

// // const LoginPage = () => {
// //   return (
// //     <Box
// //       sx={{
// //         width: 400,
// //         margin: "auto",
// //         padding: 3,
// //         borderRadius: 2,
// //         boxShadow: 3,
// //         backgroundColor: "white",
// //       }}
// //     >
// //       <Typography
// //         variant="h5"
// //         component="h1"
// //         align="center"
// //         sx={{ fontWeight: "bold", color: "#000" }}
// //       >
// //         Welcome
// //       </Typography>
// //       <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
// //         Login for a seamless experience
// //       </Typography>

// //       <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
// //         <Typography sx={{ mr: 1 }}>+91</Typography>
// //         <TextField
// //           fullWidth
// //           placeholder="Enter Mobile Number*"
// //           variant="outlined"
// //           sx={{
// //             "& .MuiOutlinedInput-root": {
// //               borderRadius: "8px",
// //             },
// //           }}
// //         />
// //       </Box>

// //       <FormControlLabel
// //         control={<Checkbox defaultChecked />}
// //         label={
// //           <Typography variant="body2">
// //             I Agree to Terms and Conditions{" "}
// //             <Link href="#" underline="hover">
// //               T&C's Privacy Policy
// //             </Link>
// //           </Typography>
// //         }
// //       />

// //       <Button
// //         fullWidth
// //         variant="contained"
// //         sx={{
// //           backgroundColor: "#007BFF",
// //           color: "#fff",
// //           borderRadius: "8px",
// //           padding: "10px",
// //           textTransform: "none",
// //           fontWeight: "bold",
// //           mb: 2,
// //           "&:hover": {
// //             backgroundColor: "#0056b3",
// //           },
// //         }}
// //       >
// //         Login with OTP
// //       </Button>

// //       <Divider sx={{ mb: 2 }}>Or Login Using</Divider>

// //       <Button
// //         fullWidth
// //         variant="outlined"
// //         startIcon={<GoogleIcon />}
// //         sx={{
// //           borderRadius: "8px",
// //           color: "#555",
// //           textTransform: "none",
// //           padding: "10px",
// //           fontWeight: "bold",
// //         }}
// //       >
// //         Google
// //       </Button>

// //       <Typography align="center" sx={{ mt: 2 }}>
// //         <Link href="#" underline="none">
// //           Skip
// //         </Link>
// //       </Typography>
// //     </Box>
// //   );
// // };

// // export default LoginPage;
