// src/redux/reducers/authReducer.js
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOAD_USER,
  AUTH_ERROR,
} from "./actionTypes";

const initialState = {
  webtoken: localStorage.getItem("webtoken"),
  isAuthenticated: localStorage.getItem("webtoken") ? true : false,
  loading: true,
  user: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("webtoken", action.payload.token);
      return {
        ...state,
        webtoken: action.payload.token,
        isAuthenticated: true,
        loading: false,
        user: action.payload?.user,
        error: null,
      };
    case LOAD_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case LOGIN_FAIL:
    case LOGOUT:
    case AUTH_ERROR:
      localStorage.removeItem("webtoken");
      return {
        ...state,
        webtoken: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
