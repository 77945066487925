// src/hooks/useFileUpload.js
import { useState } from "react";
import AWS from "aws-sdk";

const useFileUpload = () => {
 const [isUploading, setIsUploading] = useState(false);
 const [error, setError] = useState(null);
 const [uploadedFileUrl, setUploadedFileUrl] = useState("");

 const uploadFile = async (file) => {
  setIsUploading(true);
  setError(null);

  const S3 = new AWS.S3({
   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
   secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
   region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
   Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
   Key: `${Date.now()}-${file.name}`, // unique file name
   Body: file,
   ContentType: file.type,
   //    ACL: "public-read", // adjust as per your requirement
  };

  try {
   const uploadResult = await S3.upload(params).promise();
   setUploadedFileUrl(uploadResult.Location);
   return uploadResult.Location; // return the URL of the uploaded file
  } catch (err) {
   setError(err.message);
   return null; // return null if there's an error
  } finally {
   setIsUploading(false);
  }
 };

 return { uploadFile, isUploading, error, uploadedFileUrl };
};

export default useFileUpload;
