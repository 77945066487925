export const SEARCH_BUSSINESS_REQUEST = "SEARCH_BUSSINESS_REQUEST";
export const SEARCH_BUSSINESS_SUCCESS = "SEARCH_BUSSINESS_SUCCESS";
export const SEARCH_BUSSINESS_FAILED = "SEARCH_BUSSINESS_FAILED";
export const SEARCH_BUSSINESS_LOCATION = "SEARCH_BUSSINESS_LOCATION";
export const SEARCH_BUSSINESS_PINCODE = "SEARCH_BUSSINESS_PINCODE";

export const GET_BUSSINESS_DETAILS_REQUEST = "GET_BUSSINESS_DETAILS_REQUEST";
export const GET_BUSSINESS_DETAILS_SUCCESS = "GET_BUSSINESS_DETAILS_SUCCESS";
export const GET_BUSSINESS_DETAILS_FAILURE = "GET_BUSSINESS_DETAILS_FAILURE";

export const GETMORE_BUSSINESS_SUCCESS = "GETMORE_BUSSINESS_SUCCESS";
