import {
  DirectionsCar as CarIcon,
  AirportShuttle as BusIcon,
  TwoWheeler as AutoIcon,
  LocalShipping as TruckIcon,
  Moving as MoversPackersIcon,
  Commute as PickupIcon,
  CardTravel as TravelIcon,
  DirectionsTransit as TemboIcon,
  SportsMotorsports as ChampionIcon,
  EmojiTransportation as MexIcon,
} from "@mui/icons-material";
export const REACT_APP_GOOGLE_MAPS_KEY =
  "AIzaSyC-okPm2X2zr5LquFMhdXn7Y8jPMgVJDKE";

export const categories = [
  { name: "Pickup", icon: <PickupIcon /> },
  { name: "Car", icon: <CarIcon /> },
  { name: "Bus", icon: <BusIcon /> },
  { name: "Mex", icon: <MexIcon /> },
  { name: "Champion", icon: <ChampionIcon /> },
  { name: "Auto", icon: <AutoIcon /> },
  { name: "Movers & Packers", icon: <MoversPackersIcon /> },
  { name: "Tembo travels", icon: <TemboIcon /> },
  { name: "Canter loader", icon: <TruckIcon /> },
  { name: "Lodar", icon: <TruckIcon /> },
  { name: "Tata 407", icon: <TruckIcon /> },
];

export const statelist = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chandigarh",
  "Chhattisgarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Ladakh",
  "Lakshadweep",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Puducherry",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
];

export const maskMobileNumber = (mobileNumber) => {
  //   if (!mobileNumber || mobileNumber?.length < 10) {
  //     throw new Error("Invalid mobile number");
  //   }

  //   const maskedPart = mobileNumber?.slice(2, 7).replace(/./g, "x");
  //   const maskedNumber = `${mobileNumber?.slice(
  //     0,
  //     2
  //   )}${maskedPart}${mobileNumber?.slice(-3)}`;
  //   return maskedNumber;
  if (!mobileNumber) {
    throw new Error("Mobile number is required");
  }

  // Remove all spaces from the input
  const sanitizedNumber = mobileNumber.replace(/\s+/g, "");

  if (sanitizedNumber.length < 10) {
    throw new Error("Invalid mobile number");
  }

  // Mask the required part
  const maskedPart = sanitizedNumber.slice(2, 7).replace(/./g, "x");
  const maskedNumber = `${sanitizedNumber.slice(
    0,
    2
  )}${maskedPart}${sanitizedNumber.slice(-3)}`;

  return maskedNumber;
};

export const formatMobileNumber = (mobileNumber) => {
  // if (!mobileNumber) {
  //   throw new Error("Invalid mobile number");
  // }

  // // Remove any non-digit characters (optional, if input might have spaces or dashes)
  // const cleanNumber = mobileNumber.replace(/\D/g, "");

  // // Check if the number starts with +91, 91, or 0
  // if (cleanNumber.startsWith("+91")) {
  //   return cleanNumber.slice(1); // Remove the "+" prefix
  // } else if (cleanNumber.startsWith("91")) {
  //   return cleanNumber; // Already in the correct format
  // } else if (cleanNumber.startsWith("0")) {
  //   return "91" + cleanNumber.slice(1); // Replace "0" with "91"
  // } else if (cleanNumber.length === 10) {
  //   // If it's a 10-digit number without a prefix
  //   return "91" + cleanNumber;
  // } else {
  //   throw new Error("Invalid mobile number format");
  // }
  if (!mobileNumber) {
    throw new Error("Invalid mobile number");
  }

  // Remove spaces and any non-digit characters
  const cleanNumber = mobileNumber.replace(/\s+/g, "").replace(/\D/g, "");

  // Handle numbers with misplaced zeros or leading zeros
  const normalizedNumber = cleanNumber.replace(/^0+/, "");

  // Check if the number starts with 91 or is a 10-digit number
  if (normalizedNumber.startsWith("91")) {
    return normalizedNumber; // Already in the correct format
  } else if (normalizedNumber.length === 10) {
    // If it's a 10-digit number without a prefix
    return "91" + normalizedNumber;
  } else {
    throw new Error("Invalid mobile number format");
  }
};
