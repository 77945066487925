import React from "react";
import WriteReview from "../components/Review";
import { useLocation } from "react-router-dom";

const ReviewDialog = () => {
  const { hash } = useLocation();
  const [type] = hash.split("=");
  if (!hash) {
    return null;
  }
  return type.includes("#ratingandreview") ? <WriteReview /> : null;
};

export default ReviewDialog;
