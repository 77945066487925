import React, { useState } from "react";

import {
  Tab,
  Tabs,
  Grid,
  Box,
  Typography,
  Rating,
  Avatar,
  Chip,
  Card,
  CardContent,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import RecommendIcon from "@mui/icons-material/Recommend";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import ShareIcon from "@mui/icons-material/Share";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import PropTypes from "prop-types";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "swiper/css/free-mode";

import "swiper/css/thumbs";
import { getAddress } from "../utils";
import { Swiper, SwiperSlide } from "swiper/react";
import "./BussinessDetailsStyle.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import EnquiryFormDialog from "./EnquiryFormDialog";
import { useSelector } from "react-redux";
import { formatMobileNumber, maskMobileNumber } from "../constants";
import { useNavigate } from "react-router-dom";
const reviews = [
  {
    name: "Malti",
    avatar: "M",
    review:
      "My mother’s tooth implant was done by Dr Mehta. He is highly knowledgeable and experienced dentist...",
    tags: [
      "Accurate diagnosis",
      "Clean & hygienic",
      "Sterilised equipment",
      "Good supervision",
    ],
    date: "24 Feb 2023",
    helpful: 1,
  },
  {
    name: "Aditi",
    avatar: "A",
    review:
      "Dentist in Mira road Dr Mehta multispecialty dental clinic in Mira road best dentist...",
    tags: [],
    date: "28 Feb 2023",
    helpful: 0,
  },
  {
    name: "Rohan Jha",
    avatar: "R",
    review: "Main yahaan se apna dant ka ilaaj karvaya tha yahan per...",
    tags: [],
    date: "18 Feb 2023",
    helpful: 0,
  },
];
const BusinessDetailsPage = ({ business }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [open, setOpen] = useState(false);
  const { error, isAuthenticated, user } = useSelector((state) => state.auth);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const handleSendEnquiry = () => {
  //   // Construct the mailto link
  //   const email = "example@example.com";
  //   const subject = "Enquiry about the Product";
  //   const body = "Dear Team,\n\nI would like to inquire about...";

  //   // Open the default email client
  //   window.location.href = `mailto:${email}?subject=${encodeURIComponent(
  //     subject
  //   )}&body=${encodeURIComponent(body)}`;
  // };
  const handleSendEnquiry = (data) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleWhatsAppClick = (phoneNumber) => {
    // WhatsApp phone number (in international format, without leading '+')
    // const phoneNumber = "918800702886"; // Replace with your desired phone number
    const message = "Hello, I would like to inquire about...";
    const formattedNumber = formatMobileNumber(phoneNumber);

    // Construct WhatsApp URL
    const whatsappURL = `https://wa.me/${formattedNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp URL in a new tab
    window.open(whatsappURL, "_blank");
  };

  const handleCallClick = () => {
    const mobileNumber = business?.contactDetails?.mobileNumber || "";

    if (mobileNumber) {
      // Open the dialer with the specified number
      window.location.href = `tel:${mobileNumber}`;
    } else {
      alert("No contact number available");
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", p: { xs: 0.5 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card sx={{ borderRadius: 2, mb: 2 }}>
              <CardContent>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <RecommendIcon />
                        <Typography variant="h6" fontWeight="bold">
                          {business?.businessName}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 0.5 }}
                      >
                        <Chip
                          label="4.4"
                          icon={<StarIcon style={{ color: "white" }} />}
                          size="small"
                          sx={{
                            backgroundColor: "green",
                            color: "white",
                            mr: 1,
                          }}
                        />
                        <Typography variant="body2">24 Ratings</Typography>
                        <Chip
                          label="Verified"
                          icon={<VerifiedIcon />}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                        <Chip
                          label="Service Provider"
                          size="small"
                          sx={{ ml: 1 }}
                        />
                        <Chip label="Claimed" size="small" sx={{ ml: 1 }} />
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <LocationOnIcon fontSize="small" />
                        <Typography variant="body2" sx={{ ml: 0.5 }}>
                          {getAddress(business?.address)}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ ml: 2, color: "green" }}
                        >
                          Open 24 Hrs
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 2 }}>
                          2 Years in Business
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    gap: 1,
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  {!isAuthenticated ? (
                    <Button
                      variant="contained"
                      startIcon={<CallIcon />}
                      sx={{ backgroundColor: "green", mb: { xs: 1, md: 0 } }}
                      onClick={() => navigate(`#login`)}
                    >
                      {business &&
                        business?.contactDetails?.mobileNumber &&
                        maskMobileNumber(
                          business?.contactDetails?.mobileNumber
                        )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<CallIcon />}
                      onClick={handleCallClick}
                    >
                      {business?.contactDetails?.mobileNumber}
                    </Button>
                  )}

                  {!isAuthenticated ? (
                    <Button
                      onClick={() => navigate(`#login`)}
                      variant="outlined"
                      startIcon={<WhatsAppIcon />}
                    >
                      WhatsApp
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        handleWhatsAppClick(
                          business?.contactDetails?.mobileNumber
                        )
                      }
                      variant="outlined"
                      startIcon={<WhatsAppIcon />}
                    >
                      WhatsApp
                    </Button>
                  )}

                  {!isAuthenticated ? (
                    <IconButton onClick={() => navigate(`#login`)}>
                      <Button variant="contained" startIcon={<ShareIcon />}>
                        Send Enquiry
                      </Button>
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleSendEnquiry(business)}>
                      <Button variant="contained" startIcon={<ShareIcon />}>
                        Send Enquiry
                      </Button>
                    </IconButton>
                  )}

                  {!isAuthenticated ? (
                    <Rating
                      name="half-rating"
                      defaultValue={1}
                      precision={0.5}
                      onClick={() => navigate("#login")}
                    />
                  ) : (
                    <Rating
                      name="half-rating"
                      defaultValue={3}
                      precision={0.5}
                      onClick={() => navigate("#ratingandreview")}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box>
                      <Box
                        sx={{
                          width: { xs: "100%", md: 200 },
                          height: { xs: 150, md: 200 },
                          borderRadius: 1,
                          mr: { md: 2, xs: 0 },
                          mb: { xs: 2, md: 0 },
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={business?.photos?.[0]}
                          alt={"Bussiness image"}
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          sizes="banner"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <CallIcon sx={{ m: 0.5 }} />
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        // sx={{ textAlign: "left", m: 0.5 }}
                      >
                        {business?.businessName}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mt: 0.5,
                    }}
                  >
                    <Chip
                      label="4.6"
                      icon={<StarIcon style={{ color: "white" }} />}
                      size="small"
                      sx={{ backgroundColor: "green", color: "white", mr: 1 }}
                    />
                    <Typography variant="body2">632 Ratings</Typography>
                    <Chip
                      label="Verified"
                      icon={<VerifiedIcon />}
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  </Box>
                  <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                    {getAddress(business?.address)}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 1,
                      color: "gray",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    Give your rating
                    {!isAuthenticated ? (
                      <Rating
                        name="half-rating"
                        defaultValue={1}
                        precision={0.5}
                        onClick={() => navigate("#login")}
                      />
                    ) : (
                      <Rating
                        name="half-rating"
                        defaultValue={3}
                        precision={0.5}
                        onClick={() => navigate("#ratingandreview")}
                      />
                    )}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      mt: 2,
                      gap: 1,
                    }}
                  >
                    {!isAuthenticated ? (
                      <Button
                        variant="contained"
                        startIcon={<CallIcon />}
                        sx={{ backgroundColor: "green" }}
                        onClick={() => navigate(`#login`)}
                      >
                        {business &&
                          business?.contactDetails?.mobileNumber &&
                          maskMobileNumber(
                            business?.contactDetails?.mobileNumber
                          )}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<CallIcon />}
                        onClick={handleCallClick}
                      >
                        {business?.contactDetails?.mobileNumber}
                      </Button>
                    )}

                    {!isAuthenticated ? (
                      <Button
                        onClick={() => navigate(`#login`)}
                        variant="outlined"
                        startIcon={<WhatsAppIcon />}
                      >
                        WhatsApp
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          handleWhatsAppClick(
                            business?.contactDetails?.mobileNumber
                          )
                        }
                        variant="outlined"
                        startIcon={<WhatsAppIcon />}
                      >
                        WhatsApp
                      </Button>
                    )}
                  </Box>

                  {!isAuthenticated ? (
                    <IconButton
                      sx={{ mt: 2, width: "100%" }}
                      onClick={() => navigate(`#login`)}
                    >
                      <Button variant="contained" startIcon={<ShareIcon />}>
                        Send Enquiry
                      </Button>
                    </IconButton>
                  ) : (
                    <IconButton
                      sx={{ mt: 2, width: "100%" }}
                      onClick={() => handleSendEnquiry(business)}
                    >
                      <Button variant="contained" startIcon={<ShareIcon />}>
                        Send Enquiry
                      </Button>
                    </IconButton>
                  )}

                  <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                    Responds in 2 Hrs
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">Contact Janta Rides</Typography>
              <Typography variant="body2">
                Building No.-F 205, Near Parking No 5,
                <br /> Transport Nagar, New Delhi - 226012
              </Typography>
              <Button startIcon={<ShareIcon />} sx={{ mt: 2 }}>
                Share
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              sx={{ mt: 3 }}
            >
              <Tab label="Photos" {...a11yProps(0)} />
              <Tab label="Reviews" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* <CustomTabPanel value={value} index={0}></CustomTabPanel> */}
          <CustomTabPanel value={value} index={1}>
            <Box sx={{}}>
              {/* Rating Summary */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ marginBottom: 3 }}
              >
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    4.9 Rating
                  </Typography>

                  <Rating value={4.9} precision={0.1} readOnly size="large" />
                  <Typography variant="body2">
                    Janta Rides rating index based on 1,142 ratings across the
                    web
                  </Typography>
                </Box>
              </Box>

              <Divider />

              {/* User Reviews */}
              {business &&
                business?.reviews?.map((review, index) => (
                  <Card key={index} sx={{ marginBottom: 3 }}>
                    <CardContent>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Avatar>{review.avatar}</Avatar>
                        <Box>
                          <Typography fontWeight="bold">
                            {review?.user?.name ?? "Aman Kumar"}
                          </Typography>
                          <Typography variant="caption">
                            {review?.createdAt}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="body1" sx={{ marginY: 2 }}>
                        {review?.comments}
                      </Typography>
                      <Box sx={{ marginBottom: 2 }}>
                        {review?.issues?.map((tag, idx) => (
                          <Chip key={idx} label={tag} sx={{ margin: 0.5 }} />
                        ))}
                      </Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Button
                          startIcon={<ThumbUpOutlinedIcon />}
                          size="small"
                        >
                          Helpful ({review?.rating})
                        </Button>
                        <Rating
                          value={review?.rating}
                          precision={0.1}
                          readOnly
                          size="small"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                ))}
            </Box>
          </CustomTabPanel>
        </Box>
        <Box sx={{ mt: 3 }}>
          {business?.photos && (
            <Grid container spacing={2} sx={{ overflow: "hidden" }}>
              {/* Thumbnail Swiper */}
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
                style={{ width: "90%", height: "150px" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  {business?.photos?.map((imgUrl, index) => (
                    <SwiperSlide key={index}>
                      <img src={imgUrl} alt={`Thumbnail ${index}`} />
                    </SwiperSlide>
                  ))}
                </Grid>
              </Swiper>

              {/* Render Main Swiper Only After ThumbsSwiper is Ready */}
              <Swiper
                style={{
                  "--swiper-navigation-color": "#ddd",
                  "--swiper-pagination-color": "#ddd",
                  width: "90%",
                  height: "300px",
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {business?.photos?.map((imgUrl, index) => (
                  <SwiperSlide key={index}>
                    <img src={imgUrl} alt={`Slide ${index}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          )}
        </Box>
      </Box>
      <EnquiryFormDialog
        open={open}
        handleClose={handleClose}
        business={business}
      />
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default BusinessDetailsPage;
