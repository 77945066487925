import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore,
} from "redux";
import { thunk } from "redux-thunk";
import { bussinessSearchReducer } from "./searchBussiness/searchBussinessReducer";
import authReducer from "./auth/authReducer";

// Combine all reducers into the root reducer
const rootReducer = combineReducers({
  bussinessSearch: bussinessSearchReducer,
  //   auth: authReducer,
  auth: authReducer,
});

// Setting up Redux DevTools and middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the Redux store with the rootReducer and middleware
const store = legacy_createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export { store };
