import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Avatar,
  Grid,
  Chip,
  IconButton,
  Paper,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { useForm, Controller } from "react-hook-form";
import { PhotoCamera } from "@mui/icons-material";
import useFileUpload from "../hooks/useFileUpload";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAddress } from "../utils";
import axios from "axios";
const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const WriteReview = ({ businessDetails }) => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();
  const { details, isLoading } = useSelector((state) => state.bussinessSearch);
  const { user } = useSelector((state) => state.auth);
  const [ratingValue, setRatingValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [open, setOpen] = useState(true);
  console.log("useruser", user);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const issues = [
    "Carelessly handled",
    "No prior instructions",
    "Highly priced",
    "Unsupportive service",
    "Improper packaging",
    "Difficult to locate",
  ];

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      photos: [],
    },
  });
  const { uploadFile, isUploading, error } = useFileUpload();
  const selectedFiles = watch("photos", []);
  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const uploadedFileUrls = [];

    for (const file of files) {
      const uploadedUrl = await uploadFile(file);
      if (uploadedUrl) {
        uploadedFileUrls.push(uploadedUrl);
      }
    }

    setValue("photos", [...selectedFiles, ...uploadedFileUrls]);
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setValue("photos", updatedFiles);
  };

  const handleIssueClick = (issue) => {
    setSelectedIssues((prev) => {
      const updatedIssues = prev.includes(issue)
        ? prev.filter((i) => i !== issue)
        : [...prev, issue];
      setValue("issues", updatedIssues); // Update React Hook Form field
      return updatedIssues;
    });
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    // Generate preview URLs for new files
    const newPreviews = files.map((file) => URL.createObjectURL(file));

    // Append new previews to existing previews
    setImagePreviews((prev) => [...prev, ...newPreviews]);

    // Append new files to the "images" field in React Hook Form
    setValue("images", [...(getValues("images") || []), ...files]);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(pathname);
  };
  const onSubmit = async (data) => {
    console.log("Form Data:", data);

    const res = await axios
      .post(
        `https://backend-janta-rides.vercel.app/api/businesses/business/${details?._id}/review`,
        {
          userId: user?._id ?? "64b2e1f5c4578303b4f21d92",
          ...data,
        }
      )
      .then((res) => {
        const result = res?.data;
        console.log("result", result);
      })
      .catch((err) => console.log(err));
    handleClose();
  };
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backdropFilter: "blur(5px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            // width: 400,
            borderRadius: 2,
            padding: 3,
          },
        }}
        // hideBackdrop
      >
        <Box sx={{ padding: 4, maxWidth: 800, margin: "auto" }}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom>
            Write Review
          </Typography>
          <Paper variant="outlined" sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="h6">
              {details?.businessName ?? "New Bharat Packers & Movers"}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {details && getAddress(details?.address)}
            </Typography>
          </Paper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body1" gutterBottom>
              How would you rate your experience?
            </Typography>
            <Controller
              name="rating"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Rating
                  name="hover-feedback"
                  {...field}
                  //   value={value}
                  size="large"
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChange={(_, value) => field.onChange(value)}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                />
              )}
            />

            <Typography variant="body2" color="text.secondary">
              {getValues("rating") !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hover !== -1 ? hover : getValues("rating")]}
                </Box>
              )}
            </Typography>
            {errors.rating && (
              <Typography color="error">Please select a rating</Typography>
            )}
            <Box sx={{ marginTop: 3 }}>
              <Typography variant="body1" gutterBottom>
                What went wrong?
              </Typography>
              <Grid container spacing={1}>
                {issues.map((issue, index) => (
                  <Grid item key={index}>
                    <Chip
                      label={issue}
                      onClick={() => handleIssueClick(issue)}
                      color={
                        selectedIssues.includes(issue) ? "primary" : "default"
                      }
                      variant={
                        selectedIssues.includes(issue) ? "filled" : "outlined"
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <Typography variant="body1" gutterBottom>
                Tell us about your experience
              </Typography>
              <Controller
                name="comments"
                control={control}
                defaultValue=""
                rules={{ required: "Please share your experience" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    multiline
                    rows={4}
                    fullWidth
                    placeholder="Share your experience here..."
                    error={!!errors.comments}
                    helperText={errors.comments?.message}
                  />
                )}
              />
            </Box>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mb={2}>
                Upload Photos
              </Typography>
              <IconButton
                component="label"
                color="primary"
                disabled={isUploading}
                sx={{
                  border: "1px dashed",
                  width: 100,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 2,
                }}
              >
                <PhotoCamera />
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={handleFileChange}
                />
              </IconButton>
              {error && <Typography color="error">{error}</Typography>}

              <Grid container spacing={2} mt={2}>
                {selectedFiles.map((url, index) => (
                  <Grid item key={index} xs={4} md={2}>
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "150px",
                        overflow: "hidden",
                        borderRadius: 1,
                        border: "1px solid #ccc",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={url}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 4,
                          right: 4,
                          color: "red",
                        }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {/* <Box sx={{ marginTop: 3 }}>
              <Typography variant="body1" gutterBottom>
                Upload Photos
              </Typography>

              <input
                type="file"
                accept="image/*"
                multiple
                hidden
                onChange={handleFileChange}
              />
              <label htmlFor="upload-photos">
                {" "}

                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  sx={{
                    border: "1px dashed",
                    width: 100,
                    height: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 2,
                  }}
                >
                  <PhotoCamera />
                </IconButton>
              </label>

              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: 2, marginTop: 2 }}
              >
                {imagePreviews.map((preview, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    sx={{
                      width: 100,
                      height: 100,
                      objectFit: "cover",
                      borderRadius: 1,
                      border: "1px solid #ddd",
                    }}
                  />
                ))}
              </Box>
            </Box> */}
            <Box sx={{ marginTop: 4 }}>
              <Button type="submit" variant="contained" fullWidth>
                Submit Review
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
};
// <Box
//   sx={{
//     maxWidth: 800,
//     mx: "auto",
//     p: 3,
//     backgroundColor: "#fff",
//     borderRadius: 2,
//     boxShadow: 2,
//   }}
// >
//   <Typography variant="h5" sx={{ mb: 2 }}>
//     Write Review
//   </Typography>
//   <Card sx={{ display: "flex", mb: 3 }}>
//     <Avatar
//       variant="square"
//       src="https://via.placeholder.com/100"
//       alt="Company Logo"
//       sx={{ width: 100, height: 100 }}
//     />
//     <CardContent>
//       <Typography variant="h6">New Bharat Packers & Movers</Typography>
//       <Typography variant="subtitle2" color="text.secondary">
//         Nerul
//       </Typography>
//     </CardContent>
//   </Card>

//   <Box>
//     <Typography variant="subtitle1">
//       How would you rate your experience?
//     </Typography>
//     {/* <Rating name="rating" size="large" sx={{ mb: 1 }} /> */}
// <Rating
//   name="hover-feedback"
//   value={value}
//   precision={0.5}
//   getLabelText={getLabelText}
//   onChange={(event, newValue) => {
//     setValue(newValue);
//   }}
//   onChangeActive={(event, newHover) => {
//     setHover(newHover);
//   }}
//   emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
// />
// <Typography variant="body2" color="text.secondary">
//   {value !== null && (
//     <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
//   )}
// </Typography>
//   </Box>

//   <Box sx={{ my: 3 }}>
//     <Typography variant="subtitle1">What went wrong?</Typography>
//     <Grid container spacing={1} sx={{ mt: 1 }}>
//       {[
//         "Carelessly handled",
//         "No prior instructions",
//         "Highly priced",
//         "Unsupportive service",
//         "Improper packaging",
//         "Difficult to locate",
//       ].map((issue, index) => (
//         <Grid item key={index}>
//           <Chip label={issue} variant="outlined" />
//         </Grid>
//       ))}
//     </Grid>
//   </Box>

//   <Box sx={{ mb: 3 }}>
//     <Typography variant="subtitle1">
//       Tell us about your experience
//     </Typography>
//     <TextField
//       placeholder="Type here..."
//       multiline
//       rows={4}
//       fullWidth
//       sx={{ mt: 1 }}
//     />
//     {/* <Button
//       variant="contained"
//       color="secondary"
//       sx={{
//         mt: 1,
//         textTransform: "none",
//         display: "flex",
//         alignItems: "center",
//       }}
//     >
//       Let AI write
//     </Button> */}
//   </Box>

//   <Box>
//     <Typography variant="subtitle1" sx={{ mb: 1 }}>
//       Upload Photos
//     </Typography>
//     <Box
//       sx={{
//         width: 100,
//         height: 100,
//         border: "1px dashed gray",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         borderRadius: 2,
//         cursor: "pointer",
//       }}
//     >
//       <IconButton color="primary" component="label">
//         <input hidden accept="image/*" type="file" />
//         <PhotoCameraIcon />
//       </IconButton>
//     </Box>
//   </Box>

//   <Button
//     variant="contained"
//     color="primary"
//     fullWidth
//     sx={{ mt: 3, textTransform: "none" }}
//   >
//     Submit Review
//   </Button>
// </Box>

export default WriteReview;
