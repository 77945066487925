import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCNZYRnHXuEEkAKuBNmuwERnZJXAGwpXM0",
  authDomain: "jantarides-703ea.firebaseapp.com",
  projectId: "jantarides-703ea",
  storageBucket: "jantarides-703ea.firebasestorage.app",
  messagingSenderId: "731213074761",
  appId: "1:731213074761:web:c325cb6e2c71ecfe6dca98",
  measurementId: "G-0QVKP51M9P",
};
firebase.initializeApp(firebaseConfig);
//   const auth = firebase.auth();
//   export { auth };

export default firebase;
//   const provider = new firebase.auth.GoogleAuthProvider();
