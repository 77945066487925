import { useLocation } from "react-router-dom";
import LoginPage from "./Loginpage";

export const LoginDialog = () => {
  const { hash } = useLocation();
  const [type] = hash.split("=");
  if (!hash) {
    return null;
  }
  return type.includes("#login") ? <LoginPage /> : null;
};

export default LoginDialog;
